import getBaseVariables from "./getBaseVariables";
import getWeekdayDay1_Month1 from "./getWeekdayDay1_Month1";
import getMonthLength_Month1 from "./getMonthLength_Month1";

const getCellNumbers = async (year: any, month: any) => {
  const startDay = await getWeekdayDay1_Month1(year, month);
  const monthDaysCount = await getMonthLength_Month1(year, month);
  const v = await getBaseVariables();

  let xArray = [v.x1, v.x2, v.x3, v.x4, v.x5, v.x6, v.x7];
  let yArray = [v.y1, v.y2, v.y3, v.y4, v.y5, v.y6];

  let cellNumbers: any = [];

  // SUNDAY
  if (startDay === "Sunday") {
    cellNumbers = [
      { text: "1", x: xArray[0], y: yArray[0] },
      { text: "2", x: xArray[1], y: yArray[0] },
      { text: "3", x: xArray[2], y: yArray[0] },
      { text: "4", x: xArray[3], y: yArray[0] },
      { text: "5", x: xArray[4], y: yArray[0] },
      { text: "6", x: xArray[5], y: yArray[0] },
      { text: "7", x: xArray[6], y: yArray[0] },
      { text: "8", x: xArray[0], y: yArray[1] },
      { text: "9", x: xArray[1], y: yArray[1] },
      { text: "10", x: xArray[2], y: yArray[1] },
      { text: "11", x: xArray[3], y: yArray[1] },
      { text: "12", x: xArray[4], y: yArray[1] },
      { text: "13", x: xArray[5], y: yArray[1] },
      { text: "14", x: xArray[6], y: yArray[1] },
      { text: "15", x: xArray[0], y: yArray[2] },
      { text: "16", x: xArray[1], y: yArray[2] },
      { text: "17", x: xArray[2], y: yArray[2] },
      { text: "18", x: xArray[3], y: yArray[2] },
      { text: "19", x: xArray[4], y: yArray[2] },
      { text: "20", x: xArray[5], y: yArray[2] },
      { text: "21", x: xArray[6], y: yArray[2] },
      { text: "22", x: xArray[0], y: yArray[3] },
      { text: "23", x: xArray[1], y: yArray[3] },
      { text: "24", x: xArray[2], y: yArray[3] },
      { text: "25", x: xArray[3], y: yArray[3] },
      { text: "26", x: xArray[4], y: yArray[3] },
      { text: "27", x: xArray[5], y: yArray[3] },
      { text: "28", x: xArray[6], y: yArray[3] },
    ];

    // sunday - month 2
    if (monthDaysCount === 28)
      cellNumbers = [
        ...cellNumbers,
        { text: "1", x: xArray[0], y: v.y7 },
        { text: "2", x: xArray[1], y: v.y7 },
        { text: "3", x: xArray[2], y: v.y7 },
        { text: "4", x: xArray[3], y: v.y7 },
        { text: "5", x: xArray[4], y: v.y7 },
        { text: "6", x: xArray[5], y: v.y7 },
        { text: "7", x: xArray[6], y: v.y7 },
        { text: "8", x: xArray[0], y: v.y8 },
        { text: "9", x: xArray[1], y: v.y8 },
        { text: "10", x: xArray[2], y: v.y8 },
        { text: "11", x: xArray[3], y: v.y8 },
        { text: "12", x: xArray[4], y: v.y8 },
        { text: "13", x: xArray[5], y: v.y8 },
        { text: "14", x: xArray[6], y: v.y8 },
        { text: "15", x: xArray[0], y: v.y9 },
        { text: "16", x: xArray[1], y: v.y9 },
        { text: "17", x: xArray[2], y: v.y9 },
        { text: "18", x: xArray[3], y: v.y9 },
        { text: "19", x: xArray[4], y: v.y9 },
        { text: "20", x: xArray[5], y: v.y9 },
      ];
    else if (monthDaysCount === 29)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[0], y: v.y5 },
        { text: "1", x: xArray[1], y: v.y7 },
        { text: "2", x: xArray[2], y: v.y7 },
        { text: "3", x: xArray[3], y: v.y7 },
        { text: "4", x: xArray[4], y: v.y7 },
        { text: "5", x: xArray[5], y: v.y7 },
        { text: "6", x: xArray[6], y: v.y7 },
        { text: "7", x: xArray[0], y: v.y8 },
        { text: "8", x: xArray[1], y: v.y8 },
        { text: "9", x: xArray[2], y: v.y8 },
        { text: "10", x: xArray[3], y: v.y8 },
        { text: "11", x: xArray[4], y: v.y8 },
        { text: "12", x: xArray[5], y: v.y8 },
        { text: "13", x: xArray[6], y: v.y8 },
        { text: "14", x: xArray[0], y: v.y9 },
        { text: "15", x: xArray[1], y: v.y9 },
        { text: "16", x: xArray[2], y: v.y9 },
        { text: "17", x: xArray[3], y: v.y9 },
        { text: "18", x: xArray[4], y: v.y9 },
        { text: "19", x: xArray[5], y: v.y9 },
        { text: "20", x: xArray[6], y: v.y9 },
      ];
    else if (monthDaysCount === 30)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[0], y: v.y5 },
        { text: "30", x: xArray[1], y: v.y5 },
        { text: "1", x: xArray[2], y: v.y7 },
        { text: "2", x: xArray[3], y: v.y7 },
        { text: "3", x: xArray[4], y: v.y7 },
        { text: "4", x: xArray[5], y: v.y7 },
        { text: "5", x: xArray[6], y: v.y7 },
        { text: "6", x: xArray[0], y: v.y8 },
        { text: "7", x: xArray[1], y: v.y8 },
        { text: "8", x: xArray[2], y: v.y8 },
        { text: "9", x: xArray[3], y: v.y8 },
        { text: "10", x: xArray[4], y: v.y8 },
        { text: "11", x: xArray[5], y: v.y8 },
        { text: "12", x: xArray[6], y: v.y8 },
        { text: "13", x: xArray[0], y: v.y9 },
        { text: "14", x: xArray[1], y: v.y9 },
        { text: "15", x: xArray[2], y: v.y9 },
        { text: "16", x: xArray[3], y: v.y9 },
        { text: "17", x: xArray[4], y: v.y9 },
        { text: "18", x: xArray[5], y: v.y9 },
        { text: "19", x: xArray[6], y: v.y9 },
        { text: "20", x: xArray[0], y: v.y10 },
      ];
    else if (monthDaysCount === 31)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[0], y: v.y5 },
        { text: "30", x: xArray[1], y: v.y5 },
        { text: "31", x: xArray[2], y: v.y5 },
        { text: "1", x: xArray[3], y: v.y7 },
        { text: "2", x: xArray[4], y: v.y7 },
        { text: "3", x: xArray[5], y: v.y7 },
        { text: "4", x: xArray[6], y: v.y7 },
        { text: "5", x: xArray[0], y: v.y8 },
        { text: "6", x: xArray[1], y: v.y8 },
        { text: "7", x: xArray[2], y: v.y8 },
        { text: "8", x: xArray[3], y: v.y8 },
        { text: "9", x: xArray[4], y: v.y8 },
        { text: "10", x: xArray[5], y: v.y8 },
        { text: "11", x: xArray[6], y: v.y8 },
        { text: "12", x: xArray[0], y: v.y9 },
        { text: "13", x: xArray[1], y: v.y9 },
        { text: "14", x: xArray[2], y: v.y9 },
        { text: "15", x: xArray[3], y: v.y9 },
        { text: "16", x: xArray[4], y: v.y9 },
        { text: "17", x: xArray[5], y: v.y9 },
        { text: "18", x: xArray[6], y: v.y9 },
        { text: "19", x: xArray[0], y: v.y10 },
        { text: "20", x: xArray[1], y: v.y10 },
      ];
  }

  // MONDAY
  if (startDay === "Monday") {
    cellNumbers = [
      { text: "1", x: xArray[1], y: v.y1 },
      { text: "2", x: xArray[2], y: v.y1 },
      { text: "3", x: xArray[3], y: v.y1 },
      { text: "4", x: xArray[4], y: v.y1 },
      { text: "5", x: xArray[5], y: v.y1 },
      { text: "6", x: xArray[6], y: v.y1 },
      { text: "7", x: xArray[0], y: v.y2 },
      { text: "8", x: xArray[1], y: v.y2 },
      { text: "9", x: xArray[2], y: v.y2 },
      { text: "10", x: xArray[3], y: v.y2 },
      { text: "11", x: xArray[4], y: v.y2 },
      { text: "12", x: xArray[5], y: v.y2 },
      { text: "13", x: xArray[6], y: v.y2 },
      { text: "14", x: xArray[0], y: v.y3 },
      { text: "15", x: xArray[1], y: v.y3 },
      { text: "16", x: xArray[2], y: v.y3 },
      { text: "17", x: xArray[3], y: v.y3 },
      { text: "18", x: xArray[4], y: v.y3 },
      { text: "19", x: xArray[5], y: v.y3 },
      { text: "20", x: xArray[6], y: v.y3 },
      { text: "21", x: xArray[0], y: v.y4 },
      { text: "22", x: xArray[1], y: v.y4 },
      { text: "23", x: xArray[2], y: v.y4 },
      { text: "24", x: xArray[3], y: v.y4 },
      { text: "25", x: xArray[4], y: v.y4 },
      { text: "26", x: xArray[5], y: v.y4 },
      { text: "27", x: xArray[6], y: v.y4 },
      { text: "28", x: xArray[0], y: v.y5 },
    ];

    // monday - month 2
    if (monthDaysCount === 28)
      cellNumbers = [
        ...cellNumbers,
        { text: "1", x: xArray[1], y: v.y7 },
        { text: "2", x: xArray[2], y: v.y7 },
        { text: "3", x: xArray[3], y: v.y7 },
        { text: "4", x: xArray[4], y: v.y7 },
        { text: "5", x: xArray[5], y: v.y7 },
        { text: "6", x: xArray[6], y: v.y7 },
        { text: "7", x: xArray[0], y: v.y8 },
        { text: "8", x: xArray[1], y: v.y8 },
        { text: "9", x: xArray[2], y: v.y8 },
        { text: "10", x: xArray[3], y: v.y8 },
        { text: "11", x: xArray[4], y: v.y8 },
        { text: "12", x: xArray[5], y: v.y8 },
        { text: "13", x: xArray[6], y: v.y8 },
        { text: "14", x: xArray[0], y: v.y9 },
        { text: "15", x: xArray[1], y: v.y9 },
        { text: "16", x: xArray[2], y: v.y9 },
        { text: "17", x: xArray[3], y: v.y9 },
        { text: "18", x: xArray[4], y: v.y9 },
        { text: "19", x: xArray[5], y: v.y9 },
        { text: "20", x: xArray[6], y: v.y9 },
      ];
    else if (monthDaysCount === 29)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[1], y: v.y5 },
        { text: "1", x: xArray[2], y: v.y7 },
        { text: "2", x: xArray[3], y: v.y7 },
        { text: "3", x: xArray[4], y: v.y7 },
        { text: "4", x: xArray[5], y: v.y7 },
        { text: "5", x: xArray[6], y: v.y7 },
        { text: "6", x: xArray[0], y: v.y8 },
        { text: "7", x: xArray[1], y: v.y8 },
        { text: "8", x: xArray[2], y: v.y8 },
        { text: "9", x: xArray[3], y: v.y8 },
        { text: "10", x: xArray[4], y: v.y8 },
        { text: "11", x: xArray[5], y: v.y8 },
        { text: "12", x: xArray[6], y: v.y8 },
        { text: "13", x: xArray[0], y: v.y9 },
        { text: "14", x: xArray[1], y: v.y9 },
        { text: "15", x: xArray[2], y: v.y9 },
        { text: "16", x: xArray[3], y: v.y9 },
        { text: "17", x: xArray[4], y: v.y9 },
        { text: "18", x: xArray[5], y: v.y9 },
        { text: "19", x: xArray[6], y: v.y9 },
        { text: "20", x: xArray[0], y: v.y10 },
      ];
    else if (monthDaysCount === 30)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[1], y: v.y5 },
        { text: "30", x: xArray[2], y: v.y5 },
        { text: "1", x: xArray[3], y: v.y7 },
        { text: "2", x: xArray[4], y: v.y7 },
        { text: "3", x: xArray[5], y: v.y7 },
        { text: "4", x: xArray[6], y: v.y7 },
        { text: "5", x: xArray[0], y: v.y8 },
        { text: "6", x: xArray[1], y: v.y8 },
        { text: "7", x: xArray[2], y: v.y8 },
        { text: "8", x: xArray[3], y: v.y8 },
        { text: "9", x: xArray[4], y: v.y8 },
        { text: "10", x: xArray[5], y: v.y8 },
        { text: "11", x: xArray[6], y: v.y8 },
        { text: "12", x: xArray[0], y: v.y9 },
        { text: "13", x: xArray[1], y: v.y9 },
        { text: "14", x: xArray[2], y: v.y9 },
        { text: "15", x: xArray[3], y: v.y9 },
        { text: "16", x: xArray[4], y: v.y9 },
        { text: "17", x: xArray[5], y: v.y9 },
        { text: "18", x: xArray[6], y: v.y9 },
        { text: "19", x: xArray[0], y: v.y10 },
        { text: "20", x: xArray[1], y: v.y10 },
      ];
    else if (monthDaysCount === 31)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[1], y: v.y5 },
        { text: "30", x: xArray[2], y: v.y5 },
        { text: "31", x: xArray[3], y: v.y5 },
        { text: "1", x: xArray[4], y: v.y7 },
        { text: "2", x: xArray[5], y: v.y7 },
        { text: "3", x: xArray[6], y: v.y7 },
        { text: "4", x: xArray[0], y: v.y8 },
        { text: "5", x: xArray[1], y: v.y8 },
        { text: "6", x: xArray[2], y: v.y8 },
        { text: "7", x: xArray[3], y: v.y8 },
        { text: "8", x: xArray[4], y: v.y8 },
        { text: "9", x: xArray[5], y: v.y8 },
        { text: "10", x: xArray[6], y: v.y8 },
        { text: "11", x: xArray[0], y: v.y9 },
        { text: "12", x: xArray[1], y: v.y9 },
        { text: "13", x: xArray[2], y: v.y9 },
        { text: "14", x: xArray[3], y: v.y9 },
        { text: "15", x: xArray[4], y: v.y9 },
        { text: "16", x: xArray[5], y: v.y9 },
        { text: "17", x: xArray[6], y: v.y9 },
        { text: "18", x: xArray[0], y: v.y10 },
        { text: "19", x: xArray[1], y: v.y10 },
        { text: "20", x: xArray[2], y: v.y10 },
      ];
  }

  // TUESDAY
  if (startDay === "Tuesday") {
    cellNumbers = [
      { text: "1", x: xArray[2], y: v.y1 },
      { text: "2", x: xArray[3], y: v.y1 },
      { text: "3", x: xArray[4], y: v.y1 },
      { text: "4", x: xArray[5], y: v.y1 },
      { text: "5", x: xArray[6], y: v.y1 },
      { text: "6", x: xArray[0], y: v.y2 },
      { text: "7", x: xArray[1], y: v.y2 },
      { text: "8", x: xArray[2], y: v.y2 },
      { text: "9", x: xArray[3], y: v.y2 },
      { text: "10", x: xArray[4], y: v.y2 },
      { text: "11", x: xArray[5], y: v.y2 },
      { text: "12", x: xArray[6], y: v.y2 },
      { text: "13", x: xArray[0], y: v.y3 },
      { text: "14", x: xArray[1], y: v.y3 },
      { text: "15", x: xArray[2], y: v.y3 },
      { text: "16", x: xArray[3], y: v.y3 },
      { text: "17", x: xArray[4], y: v.y3 },
      { text: "18", x: xArray[5], y: v.y3 },
      { text: "19", x: xArray[6], y: v.y3 },
      { text: "20", x: xArray[0], y: v.y4 },
      { text: "21", x: xArray[1], y: v.y4 },
      { text: "22", x: xArray[2], y: v.y4 },
      { text: "23", x: xArray[3], y: v.y4 },
      { text: "24", x: xArray[4], y: v.y4 },
      { text: "25", x: xArray[5], y: v.y4 },
      { text: "26", x: xArray[6], y: v.y4 },
      { text: "27", x: xArray[0], y: v.y5 },
      { text: "28", x: xArray[1], y: v.y5 },
    ];
    //  tuesday - month 2
    if (monthDaysCount === 28)
      cellNumbers = [
        ...cellNumbers,
        { text: "1", x: xArray[2], y: v.y7 },
        { text: "2", x: xArray[3], y: v.y7 },
        { text: "3", x: xArray[4], y: v.y7 },
        { text: "4", x: xArray[5], y: v.y7 },
        { text: "5", x: xArray[6], y: v.y7 },
        { text: "6", x: xArray[0], y: v.y8 },
        { text: "7", x: xArray[1], y: v.y8 },
        { text: "8", x: xArray[2], y: v.y8 },
        { text: "9", x: xArray[3], y: v.y8 },
        { text: "10", x: xArray[4], y: v.y8 },
        { text: "11", x: xArray[5], y: v.y8 },
        { text: "12", x: xArray[6], y: v.y8 },
        { text: "13", x: xArray[0], y: v.y9 },
        { text: "14", x: xArray[1], y: v.y9 },
        { text: "15", x: xArray[2], y: v.y9 },
        { text: "16", x: xArray[3], y: v.y9 },
        { text: "17", x: xArray[4], y: v.y9 },
        { text: "18", x: xArray[5], y: v.y9 },
        { text: "19", x: xArray[6], y: v.y9 },
        { text: "20", x: xArray[0], y: v.y10 },
      ];
    else if (monthDaysCount === 29)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[2], y: v.y5 },
        { text: "1", x: xArray[3], y: v.y7 },
        { text: "2", x: xArray[4], y: v.y7 },
        { text: "3", x: xArray[5], y: v.y7 },
        { text: "4", x: xArray[6], y: v.y7 },
        { text: "5", x: xArray[0], y: v.y8 },
        { text: "6", x: xArray[1], y: v.y8 },
        { text: "7", x: xArray[2], y: v.y8 },
        { text: "8", x: xArray[3], y: v.y8 },
        { text: "9", x: xArray[4], y: v.y8 },
        { text: "10", x: xArray[5], y: v.y8 },
        { text: "11", x: xArray[6], y: v.y8 },
        { text: "12", x: xArray[0], y: v.y9 },
        { text: "13", x: xArray[1], y: v.y9 },
        { text: "14", x: xArray[2], y: v.y9 },
        { text: "15", x: xArray[3], y: v.y9 },
        { text: "16", x: xArray[4], y: v.y9 },
        { text: "17", x: xArray[5], y: v.y9 },
        { text: "18", x: xArray[6], y: v.y9 },
        { text: "19", x: xArray[0], y: v.y10 },
        { text: "20", x: xArray[1], y: v.y10 },
      ];
    else if (monthDaysCount === 30)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[2], y: v.y5 },
        { text: "30", x: xArray[3], y: v.y5 },
        { text: "1", x: xArray[4], y: v.y7 },
        { text: "2", x: xArray[5], y: v.y7 },
        { text: "3", x: xArray[6], y: v.y7 },
        { text: "4", x: xArray[0], y: v.y8 },
        { text: "5", x: xArray[1], y: v.y8 },
        { text: "6", x: xArray[2], y: v.y8 },
        { text: "7", x: xArray[3], y: v.y8 },
        { text: "8", x: xArray[4], y: v.y8 },
        { text: "9", x: xArray[5], y: v.y8 },
        { text: "10", x: xArray[6], y: v.y8 },
        { text: "11", x: xArray[0], y: v.y9 },
        { text: "12", x: xArray[1], y: v.y9 },
        { text: "13", x: xArray[2], y: v.y9 },
        { text: "14", x: xArray[3], y: v.y9 },
        { text: "15", x: xArray[4], y: v.y9 },
        { text: "16", x: xArray[5], y: v.y9 },
        { text: "17", x: xArray[6], y: v.y9 },
        { text: "18", x: xArray[0], y: v.y10 },
        { text: "19", x: xArray[1], y: v.y10 },
        { text: "20", x: xArray[2], y: v.y10 },
      ];
    else if (monthDaysCount === 31)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[2], y: v.y5 },
        { text: "30", x: xArray[3], y: v.y5 },
        { text: "31", x: xArray[4], y: v.y5 },
        { text: "1", x: xArray[5], y: v.y7 },
        { text: "2", x: xArray[6], y: v.y7 },
        { text: "3", x: xArray[0], y: v.y8 },
        { text: "4", x: xArray[1], y: v.y8 },
        { text: "5", x: xArray[2], y: v.y8 },
        { text: "6", x: xArray[3], y: v.y8 },
        { text: "7", x: xArray[4], y: v.y8 },
        { text: "8", x: xArray[5], y: v.y8 },
        { text: "9", x: xArray[6], y: v.y8 },
        { text: "10", x: xArray[0], y: v.y9 },
        { text: "11", x: xArray[1], y: v.y9 },
        { text: "12", x: xArray[2], y: v.y9 },
        { text: "13", x: xArray[3], y: v.y9 },
        { text: "14", x: xArray[4], y: v.y9 },
        { text: "15", x: xArray[5], y: v.y9 },
        { text: "16", x: xArray[6], y: v.y9 },
        { text: "17", x: xArray[0], y: v.y10 },
        { text: "18", x: xArray[1], y: v.y10 },
        { text: "19", x: xArray[2], y: v.y10 },
        { text: "20", x: xArray[3], y: v.y10 },
      ];
  }

  // WEDNESDAY
  if (startDay === "Wednesday") {
    cellNumbers = [
      { text: "1", x: xArray[3], y: v.y1 },
      { text: "2", x: xArray[4], y: v.y1 },
      { text: "3", x: xArray[5], y: v.y1 },
      { text: "4", x: xArray[6], y: v.y1 },
      { text: "5", x: xArray[0], y: v.y2 },
      { text: "6", x: xArray[1], y: v.y2 },
      { text: "7", x: xArray[2], y: v.y2 },
      { text: "8", x: xArray[3], y: v.y2 },
      { text: "9", x: xArray[4], y: v.y2 },
      { text: "10", x: xArray[5], y: v.y2 },
      { text: "11", x: xArray[6], y: v.y2 },
      { text: "12", x: xArray[0], y: v.y3 },
      { text: "13", x: xArray[1], y: v.y3 },
      { text: "14", x: xArray[2], y: v.y3 },
      { text: "15", x: xArray[3], y: v.y3 },
      { text: "16", x: xArray[4], y: v.y3 },
      { text: "17", x: xArray[5], y: v.y3 },
      { text: "18", x: xArray[6], y: v.y3 },
      { text: "19", x: xArray[0], y: v.y4 },
      { text: "20", x: xArray[1], y: v.y4 },
      { text: "21", x: xArray[2], y: v.y4 },
      { text: "22", x: xArray[3], y: v.y4 },
      { text: "23", x: xArray[4], y: v.y4 },
      { text: "24", x: xArray[5], y: v.y4 },
      { text: "25", x: xArray[6], y: v.y4 },
      { text: "26", x: xArray[0], y: v.y5 },
      { text: "27", x: xArray[1], y: v.y5 },
      { text: "28", x: xArray[2], y: v.y5 },
    ];

    // wednesday - month 2
    if (monthDaysCount === 28)
      cellNumbers = [
        ...cellNumbers,
        { text: "1", x: xArray[3], y: v.y7 },
        { text: "2", x: xArray[4], y: v.y7 },
        { text: "3", x: xArray[5], y: v.y7 },
        { text: "4", x: xArray[6], y: v.y7 },
        { text: "5", x: xArray[0], y: v.y8 },
        { text: "6", x: xArray[1], y: v.y8 },
        { text: "7", x: xArray[2], y: v.y8 },
        { text: "8", x: xArray[3], y: v.y8 },
        { text: "9", x: xArray[4], y: v.y8 },
        { text: "10", x: xArray[5], y: v.y8 },
        { text: "11", x: xArray[6], y: v.y8 },
        { text: "12", x: xArray[0], y: v.y9 },
        { text: "13", x: xArray[1], y: v.y9 },
        { text: "14", x: xArray[2], y: v.y9 },
        { text: "15", x: xArray[3], y: v.y9 },
        { text: "16", x: xArray[4], y: v.y9 },
        { text: "17", x: xArray[5], y: v.y9 },
        { text: "18", x: xArray[6], y: v.y9 },
        { text: "19", x: xArray[0], y: v.y10 },
        { text: "20", x: xArray[1], y: v.y10 },
      ];
    else if (monthDaysCount === 29)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[3], y: v.y5 },
        { text: "1", x: xArray[4], y: v.y7 },
        { text: "2", x: xArray[5], y: v.y7 },
        { text: "3", x: xArray[6], y: v.y7 },
        { text: "4", x: xArray[0], y: v.y8 },
        { text: "5", x: xArray[1], y: v.y8 },
        { text: "6", x: xArray[2], y: v.y8 },
        { text: "7", x: xArray[3], y: v.y8 },
        { text: "8", x: xArray[4], y: v.y8 },
        { text: "9", x: xArray[5], y: v.y8 },
        { text: "10", x: xArray[6], y: v.y8 },
        { text: "11", x: xArray[0], y: v.y9 },
        { text: "12", x: xArray[1], y: v.y9 },
        { text: "13", x: xArray[2], y: v.y9 },
        { text: "14", x: xArray[3], y: v.y9 },
        { text: "15", x: xArray[4], y: v.y9 },
        { text: "16", x: xArray[5], y: v.y9 },
        { text: "17", x: xArray[6], y: v.y9 },
        { text: "18", x: xArray[0], y: v.y10 },
        { text: "19", x: xArray[1], y: v.y10 },
        { text: "20", x: xArray[2], y: v.y10 },
      ];
    else if (monthDaysCount === 30)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[3], y: v.y5 },
        { text: "30", x: xArray[4], y: v.y5 },
        { text: "1", x: xArray[5], y: v.y7 },
        { text: "2", x: xArray[6], y: v.y7 },
        { text: "3", x: xArray[0], y: v.y8 },
        { text: "4", x: xArray[1], y: v.y8 },
        { text: "5", x: xArray[2], y: v.y8 },
        { text: "6", x: xArray[3], y: v.y8 },
        { text: "7", x: xArray[4], y: v.y8 },
        { text: "8", x: xArray[5], y: v.y8 },
        { text: "9", x: xArray[6], y: v.y8 },
        { text: "10", x: xArray[0], y: v.y9 },
        { text: "11", x: xArray[1], y: v.y9 },
        { text: "12", x: xArray[2], y: v.y9 },
        { text: "13", x: xArray[3], y: v.y9 },
        { text: "14", x: xArray[4], y: v.y9 },
        { text: "15", x: xArray[5], y: v.y9 },
        { text: "16", x: xArray[6], y: v.y9 },
        { text: "17", x: xArray[0], y: v.y10 },
        { text: "18", x: xArray[1], y: v.y10 },
        { text: "19", x: xArray[2], y: v.y10 },
        { text: "20", x: xArray[3], y: v.y10 },
      ];
    else if (monthDaysCount === 31)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[3], y: v.y5 },
        { text: "30", x: xArray[4], y: v.y5 },
        { text: "31", x: xArray[5], y: v.y5 },
        { text: "1", x: xArray[6], y: v.y7 },
        { text: "2", x: xArray[0], y: v.y8 },
        { text: "3", x: xArray[1], y: v.y8 },
        { text: "4", x: xArray[2], y: v.y8 },
        { text: "5", x: xArray[3], y: v.y8 },
        { text: "6", x: xArray[4], y: v.y8 },
        { text: "7", x: xArray[5], y: v.y8 },
        { text: "8", x: xArray[6], y: v.y8 },
        { text: "9", x: xArray[0], y: v.y9 },
        { text: "10", x: xArray[1], y: v.y9 },
        { text: "11", x: xArray[2], y: v.y9 },
        { text: "12", x: xArray[3], y: v.y9 },
        { text: "13", x: xArray[4], y: v.y9 },
        { text: "14", x: xArray[5], y: v.y9 },
        { text: "15", x: xArray[6], y: v.y9 },
        { text: "16", x: xArray[0], y: v.y10 },
        { text: "17", x: xArray[1], y: v.y10 },
        { text: "18", x: xArray[2], y: v.y10 },
        { text: "19", x: xArray[3], y: v.y10 },
        { text: "20", x: xArray[4], y: v.y10 },
      ];
  }

  // THURSDAY
  if (startDay === "Thursday") {
    cellNumbers = [
      { text: "1", x: xArray[4], y: v.y1 },
      { text: "2", x: xArray[5], y: v.y1 },
      { text: "3", x: xArray[6], y: v.y1 },
      { text: "4", x: xArray[0], y: v.y2 },
      { text: "5", x: xArray[1], y: v.y2 },
      { text: "6", x: xArray[2], y: v.y2 },
      { text: "7", x: xArray[3], y: v.y2 },
      { text: "8", x: xArray[4], y: v.y2 },
      { text: "9", x: xArray[5], y: v.y2 },
      { text: "10", x: xArray[6], y: v.y2 },
      { text: "11", x: xArray[0], y: v.y3 },
      { text: "12", x: xArray[1], y: v.y3 },
      { text: "13", x: xArray[2], y: v.y3 },
      { text: "14", x: xArray[3], y: v.y3 },
      { text: "15", x: xArray[4], y: v.y3 },
      { text: "16", x: xArray[5], y: v.y3 },
      { text: "17", x: xArray[6], y: v.y3 },
      { text: "18", x: xArray[0], y: v.y4 },
      { text: "19", x: xArray[1], y: v.y4 },
      { text: "20", x: xArray[2], y: v.y4 },
      { text: "21", x: xArray[3], y: v.y4 },
      { text: "22", x: xArray[4], y: v.y4 },
      { text: "23", x: xArray[5], y: v.y4 },
      { text: "24", x: xArray[6], y: v.y4 },
      { text: "25", x: xArray[0], y: v.y5 },
      { text: "26", x: xArray[1], y: v.y5 },
      { text: "27", x: xArray[2], y: v.y5 },
      { text: "28", x: xArray[3], y: v.y5 },
    ];

    // thursday - month 2
    if (monthDaysCount === 28)
      cellNumbers = [
        ...cellNumbers,
        { text: "1", x: xArray[4], y: v.y7 },
        { text: "2", x: xArray[5], y: v.y7 },
        { text: "3", x: xArray[6], y: v.y7 },
        { text: "4", x: xArray[0], y: v.y8 },
        { text: "5", x: xArray[1], y: v.y8 },
        { text: "6", x: xArray[2], y: v.y8 },
        { text: "7", x: xArray[3], y: v.y8 },
        { text: "8", x: xArray[4], y: v.y8 },
        { text: "9", x: xArray[5], y: v.y8 },
        { text: "10", x: xArray[6], y: v.y8 },
        { text: "11", x: xArray[0], y: v.y9 },
        { text: "12", x: xArray[1], y: v.y9 },
        { text: "13", x: xArray[2], y: v.y9 },
        { text: "14", x: xArray[3], y: v.y9 },
        { text: "15", x: xArray[4], y: v.y9 },
        { text: "16", x: xArray[5], y: v.y9 },
        { text: "17", x: xArray[6], y: v.y9 },
        { text: "18", x: xArray[0], y: v.y10 },
        { text: "19", x: xArray[1], y: v.y10 },
        { text: "20", x: xArray[2], y: v.y10 },
      ];
    else if (monthDaysCount === 29)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[4], y: v.y5 },
        { text: "1", x: xArray[5], y: v.y7 },
        { text: "2", x: xArray[6], y: v.y7 },
        { text: "3", x: xArray[0], y: v.y8 },
        { text: "4", x: xArray[1], y: v.y8 },
        { text: "5", x: xArray[2], y: v.y8 },
        { text: "6", x: xArray[3], y: v.y8 },
        { text: "7", x: xArray[4], y: v.y8 },
        { text: "8", x: xArray[5], y: v.y8 },
        { text: "9", x: xArray[6], y: v.y8 },
        { text: "10", x: xArray[0], y: v.y9 },
        { text: "11", x: xArray[1], y: v.y9 },
        { text: "12", x: xArray[2], y: v.y9 },
        { text: "13", x: xArray[3], y: v.y9 },
        { text: "14", x: xArray[4], y: v.y9 },
        { text: "15", x: xArray[5], y: v.y9 },
        { text: "16", x: xArray[6], y: v.y9 },
        { text: "17", x: xArray[0], y: v.y10 },
        { text: "18", x: xArray[1], y: v.y10 },
        { text: "19", x: xArray[2], y: v.y10 },
        { text: "20", x: xArray[3], y: v.y10 },
      ];
    else if (monthDaysCount === 30)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[4], y: v.y5 },
        { text: "30", x: xArray[5], y: v.y5 },
        { text: "1", x: xArray[6], y: v.y7 },
        { text: "2", x: xArray[0], y: v.y8 },
        { text: "3", x: xArray[1], y: v.y8 },
        { text: "4", x: xArray[2], y: v.y8 },
        { text: "5", x: xArray[3], y: v.y8 },
        { text: "6", x: xArray[4], y: v.y8 },
        { text: "7", x: xArray[5], y: v.y8 },
        { text: "8", x: xArray[6], y: v.y8 },
        { text: "9", x: xArray[0], y: v.y9 },
        { text: "10", x: xArray[1], y: v.y9 },
        { text: "11", x: xArray[2], y: v.y9 },
        { text: "12", x: xArray[3], y: v.y9 },
        { text: "13", x: xArray[4], y: v.y9 },
        { text: "14", x: xArray[5], y: v.y9 },
        { text: "15", x: xArray[6], y: v.y9 },
        { text: "16", x: xArray[0], y: v.y10 },
        { text: "17", x: xArray[1], y: v.y10 },
        { text: "18", x: xArray[2], y: v.y10 },
        { text: "19", x: xArray[3], y: v.y10 },
        { text: "20", x: xArray[4], y: v.y10 },
      ];
    else if (monthDaysCount === 31)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[4], y: v.y5 },
        { text: "30", x: xArray[5], y: v.y5 },
        { text: "31", x: xArray[6], y: v.y5 },
        { text: "1", x: xArray[0], y: v.y7 },
        { text: "2", x: xArray[1], y: v.y7 },
        { text: "3", x: xArray[2], y: v.y7 },
        { text: "4", x: xArray[3], y: v.y7 },
        { text: "5", x: xArray[4], y: v.y7 },
        { text: "6", x: xArray[5], y: v.y7 },
        { text: "7", x: xArray[6], y: v.y7 },
        { text: "8", x: xArray[0], y: v.y8 },
        { text: "9", x: xArray[1], y: v.y8 },
        { text: "10", x: xArray[2], y: v.y8 },
        { text: "11", x: xArray[3], y: v.y8 },
        { text: "12", x: xArray[4], y: v.y8 },
        { text: "13", x: xArray[5], y: v.y8 },
        { text: "14", x: xArray[6], y: v.y8 },
        { text: "15", x: xArray[0], y: v.y9 },
        { text: "16", x: xArray[1], y: v.y9 },
        { text: "17", x: xArray[2], y: v.y9 },
        { text: "18", x: xArray[3], y: v.y9 },
        { text: "19", x: xArray[4], y: v.y9 },
        { text: "20", x: xArray[5], y: v.y9 },
      ];
  }

  // FRIDAY
  if (startDay === "Friday") {
    cellNumbers = [
      { text: "1", x: xArray[5], y: v.y1 },
      { text: "2", x: xArray[6], y: v.y1 },
      { text: "3", x: xArray[0], y: v.y2 },
      { text: "4", x: xArray[1], y: v.y2 },
      { text: "5", x: xArray[2], y: v.y2 },
      { text: "6", x: xArray[3], y: v.y2 },
      { text: "7", x: xArray[4], y: v.y2 },
      { text: "8", x: xArray[5], y: v.y2 },
      { text: "9", x: xArray[6], y: v.y2 },
      { text: "10", x: xArray[0], y: v.y3 },
      { text: "11", x: xArray[1], y: v.y3 },
      { text: "12", x: xArray[2], y: v.y3 },
      { text: "13", x: xArray[3], y: v.y3 },
      { text: "14", x: xArray[4], y: v.y3 },
      { text: "15", x: xArray[5], y: v.y3 },
      { text: "16", x: xArray[6], y: v.y3 },
      { text: "17", x: xArray[0], y: v.y4 },
      { text: "18", x: xArray[1], y: v.y4 },
      { text: "19", x: xArray[2], y: v.y4 },
      { text: "20", x: xArray[3], y: v.y4 },
      { text: "21", x: xArray[4], y: v.y4 },
      { text: "22", x: xArray[5], y: v.y4 },
      { text: "23", x: xArray[6], y: v.y4 },
      { text: "24", x: xArray[0], y: v.y5 },
      { text: "25", x: xArray[1], y: v.y5 },
      { text: "26", x: xArray[2], y: v.y5 },
      { text: "27", x: xArray[3], y: v.y5 },
      { text: "28", x: xArray[4], y: v.y5 },
    ];
    // friday - month 2
    if (monthDaysCount === 28)
      cellNumbers = [
        ...cellNumbers,
        { text: "1", x: xArray[5], y: v.y7 },
        { text: "2", x: xArray[6], y: v.y7 },
        { text: "3", x: xArray[0], y: v.y8 },
        { text: "4", x: xArray[1], y: v.y8 },
        { text: "5", x: xArray[2], y: v.y8 },
        { text: "6", x: xArray[3], y: v.y8 },
        { text: "7", x: xArray[4], y: v.y8 },
        { text: "8", x: xArray[5], y: v.y8 },
        { text: "9", x: xArray[6], y: v.y8 },
        { text: "10", x: xArray[0], y: v.y9 },
        { text: "11", x: xArray[1], y: v.y9 },
        { text: "12", x: xArray[2], y: v.y9 },
        { text: "13", x: xArray[3], y: v.y9 },
        { text: "14", x: xArray[4], y: v.y9 },
        { text: "15", x: xArray[5], y: v.y9 },
        { text: "16", x: xArray[6], y: v.y9 },
        { text: "17", x: xArray[0], y: v.y10 },
        { text: "18", x: xArray[1], y: v.y10 },
        { text: "19", x: xArray[2], y: v.y10 },
        { text: "20", x: xArray[3], y: v.y10 },
      ];
    else if (monthDaysCount === 29)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[5], y: v.y5 },
        { text: "1", x: xArray[6], y: v.y7 },
        { text: "2", x: xArray[0], y: v.y8 },
        { text: "3", x: xArray[1], y: v.y8 },
        { text: "4", x: xArray[2], y: v.y8 },
        { text: "5", x: xArray[3], y: v.y8 },
        { text: "6", x: xArray[4], y: v.y8 },
        { text: "7", x: xArray[5], y: v.y8 },
        { text: "8", x: xArray[6], y: v.y8 },
        { text: "9", x: xArray[0], y: v.y9 },
        { text: "10", x: xArray[1], y: v.y9 },
        { text: "11", x: xArray[2], y: v.y9 },
        { text: "12", x: xArray[3], y: v.y9 },
        { text: "13", x: xArray[4], y: v.y9 },
        { text: "14", x: xArray[5], y: v.y9 },
        { text: "15", x: xArray[6], y: v.y9 },
        { text: "16", x: xArray[0], y: v.y10 },
        { text: "17", x: xArray[1], y: v.y10 },
        { text: "18", x: xArray[2], y: v.y10 },
        { text: "19", x: xArray[3], y: v.y10 },
        { text: "20", x: xArray[4], y: v.y10 },
      ];
    else if (monthDaysCount === 30)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[5], y: v.y5 },
        { text: "30", x: xArray[6], y: v.y5 },
        { text: "1", x: xArray[0], y: v.y7 },
        { text: "2", x: xArray[1], y: v.y7 },
        { text: "3", x: xArray[2], y: v.y7 },
        { text: "4", x: xArray[3], y: v.y7 },
        { text: "5", x: xArray[4], y: v.y7 },
        { text: "6", x: xArray[5], y: v.y7 },
        { text: "7", x: xArray[6], y: v.y7 },
        { text: "8", x: xArray[0], y: v.y8 },
        { text: "9", x: xArray[1], y: v.y8 },
        { text: "10", x: xArray[2], y: v.y8 },
        { text: "11", x: xArray[3], y: v.y8 },
        { text: "12", x: xArray[4], y: v.y8 },
        { text: "13", x: xArray[5], y: v.y8 },
        { text: "14", x: xArray[6], y: v.y8 },
        { text: "15", x: xArray[0], y: v.y9 },
        { text: "16", x: xArray[1], y: v.y9 },
        { text: "17", x: xArray[2], y: v.y9 },
        { text: "18", x: xArray[3], y: v.y9 },
        { text: "19", x: xArray[4], y: v.y9 },
        { text: "20", x: xArray[5], y: v.y9 },
      ];
    else if (monthDaysCount === 31)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[5], y: v.y5 },
        { text: "30", x: xArray[6], y: v.y5 },
        { text: "31", x: xArray[0], y: v.y6 },
        { text: "1", x: xArray[1], y: v.y7 },
        { text: "2", x: xArray[2], y: v.y7 },
        { text: "3", x: xArray[3], y: v.y7 },
        { text: "4", x: xArray[4], y: v.y7 },
        { text: "5", x: xArray[5], y: v.y7 },
        { text: "6", x: xArray[6], y: v.y7 },
        { text: "7", x: xArray[0], y: v.y8 },
        { text: "8", x: xArray[1], y: v.y8 },
        { text: "9", x: xArray[2], y: v.y8 },
        { text: "10", x: xArray[3], y: v.y8 },
        { text: "11", x: xArray[4], y: v.y8 },
        { text: "12", x: xArray[5], y: v.y8 },
        { text: "13", x: xArray[6], y: v.y8 },
        { text: "14", x: xArray[0], y: v.y9 },
        { text: "15", x: xArray[1], y: v.y9 },
        { text: "16", x: xArray[2], y: v.y9 },
        { text: "17", x: xArray[3], y: v.y9 },
        { text: "18", x: xArray[4], y: v.y9 },
        { text: "19", x: xArray[5], y: v.y9 },
        { text: "20", x: xArray[6], y: v.y9 },
      ];
  }

  // SATURDAY
  if (startDay === "Saturday") {
    cellNumbers = [
      { text: "1", x: xArray[6], y: v.y1 },
      { text: "2", x: xArray[0], y: v.y2 },
      { text: "3", x: xArray[1], y: v.y2 },
      { text: "4", x: xArray[2], y: v.y2 },
      { text: "5", x: xArray[3], y: v.y2 },
      { text: "6", x: xArray[4], y: v.y2 },
      { text: "7", x: xArray[5], y: v.y2 },
      { text: "8", x: xArray[6], y: v.y2 },
      { text: "9", x: xArray[0], y: v.y3 },
      { text: "10", x: xArray[1], y: v.y3 },
      { text: "11", x: xArray[2], y: v.y3 },
      { text: "12", x: xArray[3], y: v.y3 },
      { text: "13", x: xArray[4], y: v.y3 },
      { text: "14", x: xArray[5], y: v.y3 },
      { text: "15", x: xArray[6], y: v.y3 },
      { text: "16", x: xArray[0], y: v.y4 },
      { text: "17", x: xArray[1], y: v.y4 },
      { text: "18", x: xArray[2], y: v.y4 },
      { text: "19", x: xArray[3], y: v.y4 },
      { text: "20", x: xArray[4], y: v.y4 },
      { text: "21", x: xArray[5], y: v.y4 },
      { text: "22", x: xArray[6], y: v.y4 },
      { text: "23", x: xArray[0], y: v.y5 },
      { text: "24", x: xArray[1], y: v.y5 },
      { text: "25", x: xArray[2], y: v.y5 },
      { text: "26", x: xArray[3], y: v.y5 },
      { text: "27", x: xArray[4], y: v.y5 },
      { text: "28", x: xArray[5], y: v.y5 },
    ];

    // saturday - month 2
    if (monthDaysCount === 28)
      cellNumbers = [
        ...cellNumbers,
        { text: "1", x: xArray[6], y: v.y7 },
        { text: "2", x: xArray[0], y: v.y8 },
        { text: "3", x: xArray[1], y: v.y8 },
        { text: "4", x: xArray[2], y: v.y8 },
        { text: "5", x: xArray[3], y: v.y8 },
        { text: "6", x: xArray[4], y: v.y8 },
        { text: "7", x: xArray[5], y: v.y8 },
        { text: "8", x: xArray[6], y: v.y8 },
        { text: "9", x: xArray[0], y: v.y9 },
        { text: "10", x: xArray[1], y: v.y9 },
        { text: "11", x: xArray[2], y: v.y9 },
        { text: "12", x: xArray[3], y: v.y9 },
        { text: "13", x: xArray[4], y: v.y9 },
        { text: "14", x: xArray[5], y: v.y9 },
        { text: "15", x: xArray[6], y: v.y9 },
        { text: "16", x: xArray[0], y: v.y10 },
        { text: "17", x: xArray[1], y: v.y10 },
        { text: "18", x: xArray[2], y: v.y10 },
        { text: "19", x: xArray[3], y: v.y10 },
        { text: "20", x: xArray[4], y: v.y10 },
      ];
    else if (monthDaysCount === 29)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[6], y: v.y5 },
        { text: "1", x: xArray[0], y: v.y7 },
        { text: "2", x: xArray[1], y: v.y7 },
        { text: "3", x: xArray[2], y: v.y7 },
        { text: "4", x: xArray[3], y: v.y7 },
        { text: "5", x: xArray[4], y: v.y7 },
        { text: "6", x: xArray[5], y: v.y7 },
        { text: "7", x: xArray[6], y: v.y7 },
        { text: "8", x: xArray[0], y: v.y8 },
        { text: "9", x: xArray[1], y: v.y8 },
        { text: "10", x: xArray[2], y: v.y8 },
        { text: "11", x: xArray[3], y: v.y8 },
        { text: "12", x: xArray[4], y: v.y8 },
        { text: "13", x: xArray[5], y: v.y8 },
        { text: "14", x: xArray[6], y: v.y8 },
        { text: "15", x: xArray[0], y: v.y9 },
        { text: "16", x: xArray[1], y: v.y9 },
        { text: "17", x: xArray[2], y: v.y9 },
        { text: "18", x: xArray[3], y: v.y9 },
        { text: "19", x: xArray[4], y: v.y9 },
        { text: "20", x: xArray[5], y: v.y9 },
      ];
    else if (monthDaysCount === 30)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[6], y: v.y5 },
        { text: "30", x: xArray[0], y: v.y6 },
        { text: "1", x: xArray[1], y: v.y7 },
        { text: "2", x: xArray[2], y: v.y7 },
        { text: "3", x: xArray[3], y: v.y7 },
        { text: "4", x: xArray[4], y: v.y7 },
        { text: "5", x: xArray[5], y: v.y7 },
        { text: "6", x: xArray[6], y: v.y7 },
        { text: "7", x: xArray[0], y: v.y8 },
        { text: "8", x: xArray[1], y: v.y8 },
        { text: "9", x: xArray[2], y: v.y8 },
        { text: "10", x: xArray[3], y: v.y8 },
        { text: "11", x: xArray[4], y: v.y8 },
        { text: "12", x: xArray[5], y: v.y8 },
        { text: "13", x: xArray[6], y: v.y8 },
        { text: "14", x: xArray[0], y: v.y9 },
        { text: "15", x: xArray[1], y: v.y9 },
        { text: "16", x: xArray[2], y: v.y9 },
        { text: "17", x: xArray[3], y: v.y9 },
        { text: "18", x: xArray[4], y: v.y9 },
        { text: "19", x: xArray[5], y: v.y9 },
        { text: "20", x: xArray[6], y: v.y9 },
      ];
    else if (monthDaysCount === 31)
      cellNumbers = [
        ...cellNumbers,
        { text: "29", x: xArray[6], y: v.y5 },
        { text: "30", x: xArray[0], y: v.y6 },
        { text: "31", x: xArray[1], y: v.y6 },
        { text: "1", x: xArray[2], y: v.y7 },
        { text: "2", x: xArray[3], y: v.y7 },
        { text: "3", x: xArray[4], y: v.y7 },
        { text: "4", x: xArray[5], y: v.y7 },
        { text: "5", x: xArray[6], y: v.y7 },
        { text: "6", x: xArray[0], y: v.y8 },
        { text: "7", x: xArray[1], y: v.y8 },
        { text: "8", x: xArray[2], y: v.y8 },
        { text: "9", x: xArray[3], y: v.y8 },
        { text: "10", x: xArray[4], y: v.y8 },
        { text: "11", x: xArray[5], y: v.y8 },
        { text: "12", x: xArray[6], y: v.y8 },
        { text: "13", x: xArray[0], y: v.y9 },
        { text: "14", x: xArray[1], y: v.y9 },
        { text: "15", x: xArray[2], y: v.y9 },
        { text: "16", x: xArray[3], y: v.y9 },
        { text: "17", x: xArray[4], y: v.y9 },
        { text: "18", x: xArray[5], y: v.y9 },
        { text: "19", x: xArray[6], y: v.y9 },
        { text: "20", x: xArray[0], y: v.y10 },
      ];
  }

  return cellNumbers;
};

export default getCellNumbers;
